<template>
  <div>
    <Menu/>
    <div>
      		<!-- crumbs -->
		<div class="crumbs">
			<div class="content d-flex d-flex-middle">
				<p class="color9 fontSize12">当前所在位置：</p>
				<router-link to="/" class="color9 fontSize12">首页</router-link>
				<p class="color9 fontSize12 and">></p>
				<p class="color fontSize12">名师堂</p>
			</div>
		</div>
		<!-- classify -->
		<div class="classify">
			<div class="content classifyList bg_fff">
          <div class="classifyLi d-flex d-flex-top">
            <p class="classifyLi_title">行业分类</p>
            <div class="classification d-flex d-flex-middle d-flex-wrap">
              <p :class="{active:industryActive==index}" v-for="(item,index) in industryList" :key="index" @click="changeIndustryActive(item.Industry_Id,index)">{{item.Industry_Title}}</p>
            </div>
          </div>
          <div class="classifyLi d-flex d-flex-top">
            <p class="classifyLi_title">考试分类</p>
            <div class="cl_list">
              <div class="cl_li" v-show="industryActive==index" v-for="(item,index) in industryList" :key="index">
                <div class="classifyLi_list d-flex d-flex-middle d-flex-wrap">
                  <p :class="{active:industryChilActive==indexClil}" v-for="(itemClil,indexClil) in item.chil" :key="indexClil" @click="changeIndustryChilActive(itemClil.Industry_Id,indexClil)">{{itemClil.Industry_Title}}</p>
                </div>
              </div>
            </div>
          </div>
			
			
			</div>
		</div>
		<!-- list -->
		<div class="list">
			<div class="content d-flex d-flex-middle d-flex-wrap bg_fff">
				<div class="li d-flex d-flex-top" v-for="(item,index) in listData" :key="index">
					<div class="liL">
						<img :src="$store.state.httpUrl + item.teacher_HeadImgPath" class="li_img" />
						<div class="d-flex d-flex-middle d-flex-center">
							<router-link :to="'/TeacherDetail/' + item.teacher_Id">试听TA的课</router-link>
							<img src="../assets/images/listen.png" />
						</div>
					</div>
					<div class="liR">
						<p class="li_name">{{item.teacher_RealName}}</p>
						<p class="li_label txtOver">{{item.industry_Title}}</p>
						<p class="li_year txtOver2"><span>教龄：</span>{{item.teacher_Experience}}</p>
						<p class="li_txt txtOver4"><span>简介：</span>{{item.teacher_Indroce}}</p>
					</div>
				</div>
			</div>
			<div class="none" v-if="listData.length==0">
				<img src="../assets/images/none.png"/>
			</div>
		</div>

		      <div style="text-align: center; margin-top: 60px;margin-bottom: 60px;">
        <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="total" :page-size="8">
        </el-pagination>
      </div>
    </div>
    <Foot/>
    <RightGuide/>
    
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import Foot from "@/components/Foot";
import RightGuide from "@/components/RightGuide";
import { industry, splitListTeacher } from "@/api/app"
export default {
  name: "Home",
  components: {
    Menu, Foot, RightGuide
  },
  data() {
    return {
      industryActive: 0,	//所有分类数据active 一级分类 
      industryChilActive: -1,	//所有分类数据active 二级分类
      industryList: [],// 所有分类数据，对应页面行业分类用一级分类，考试分类用二级分类
      listData: [],		//数据列表
      searchVal:'',
	    total:0,//总页数
    };
  },
  mounted() {
	  this.getIndustry()
  },

  methods: {

    //点击行业分类
    changeIndustryActive(id, index) {
      this.industryChilActive = -1
      this.industryActive = index
      this.getSplitListTeacher()
    },
    //点击考试分类
    changeIndustryChilActive(id, index) {
      this.industryChilActive = index
      this.getSplitListTeacher()
    },

    //全部分类
    getIndustry() {
      const params = {}
      industry(params).then((res) => {
        if (res.status) {
          const data = res.data
          this.industryList = data

          //获取列表数据
          this.getSplitListTeacher()
        }
      })
    },

	//分页
	handleCurrentChange(page){
		this.getPlitListCourse(page)
	},

    //数据列表
    getSplitListTeacher(page) {
      const params = {
        industry_Id: '',
		pageSize:10,
        page: page || 1,
      }

      //判断是否选中考试（二级）分类, 二级选中就传二级,反之传一级
      if (this.industryChilActive == -1) {
        params.industry_Id = this.industryList[this.industryActive].Industry_Id
      } else {
        params.industry_Id = this.industryList[this.industryActive].chil[this.industryChilActive].Industry_Id
      }


      splitListTeacher(params).then((res) => {
        if (res.status) {
          const data = res.data
          this.listData = data.listData
		  console.log(data)
		  this.total = data.totalCount
        }
      })

    },
  },
};
</script>

<style >
/*  */
body{
	background-color: #FAFAFA;
}
/*  */
.classify{
	margin-bottom: 50px;
}
.classify .content{
	box-sizing: border-box;
	padding: 10px 20px;
	border-radius: 10px;
}
.classifyLi{
	margin-bottom: 10px;
}
.classifyLi .classifyLi_title{
	width: 100px;
	line-height: 30px;
	color: #999;
	font-size: 14px;
	margin: 5px 0;
}
.classifyLi_list,.cl_list{
	flex: 1;
}
.classifyLi_list p,
.classification p{
	line-height: 30px;
	padding: 0 10px;
	cursor: pointer;
	color: #333;
	margin: 5px;
}
.classifyLi_list .active,
.classification .active{
	background-color: #0087ED;
	color: #fff;
	border-radius: 5px;
}
.cl_li{
	/* display: none; */
}
.cl_li.show{
	display: block;
}
/*  */
.list{
	padding-bottom: 100px;
}
.list .content{
	border-radius: 10px;
}
.li{
	width: calc(100% / 3);
	box-sizing: border-box;
	padding: 25px 15px;
}
.li .li_img{
	width: 180px;
	height: 180px;
	margin-bottom: 20px;
}
.liL div{
	margin: 0 auto;
	width: 130px;
	height: 36px;
	background: #FFFFFF;
	border: 1px solid #0087ED;
	border-radius: 18px;
}
.liL div a{
	color: #0087ED;
	font-size: 14px;
	margin-right: 5px;
	line-height: 34px;
}
.liL div img{
	width: 20px;
}
.liR{
	flex:1;
	margin-left: 20px;
}
.li_name{
	color: #333;
	font-size: 24px;
	margin-bottom: 20px;
}
.li_label{
	max-width: 100%;
	min-width: 90px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	background: #E4F2FD;
	border-radius: 15px;
	color: #0087ED;
	font-size: 12px;
	margin-bottom: 20px;
}
.li_year{
	color: #333;
	font-size: 14px;
	line-height: 20px;
	margin-bottom: 5px;
}
.li_year span,
.li_txt span{
	color: #999;
}
.li_txt{
	height: 96px;
	line-height: 24px;
	color: #333;
	font-size: 14px;
}
</style>